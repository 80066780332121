import { useEffect, useMemo, useState } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import HomeIcon from '@mui/icons-material/Home';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PollIcon from '@mui/icons-material/Poll';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BusinessIcon from '@mui/icons-material/Business';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import commonApiService from 'src/services/commonApi';

export const useSections = () => {
  const [sideMenus, setSideMenus] = useState([]);


  /**
   * api used to get sideBar menus before rendering this page
   * @date 7/6/2023 - 7:15:10 PM
   */
  function getsidebarmenu() {
    const fetchMenuBar = async () => {
      try {
        const data = await commonApiService.getSideBarMenus();
        setSideMenus(data);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchMenuBar();
  }

  useEffect(() => {
    getsidebarmenu();
  }, [])

  const menuItems = useMemo(() => {
    if (sideMenus.length === 0) {
      return []; // Return empty array while sideMenus is being fetched
    }

    return sideMenus.map((menu) => ({
      title: menu.menu_text,
      path: menu.menu_link,
      icon: (
        <SvgIcon fontSize="small">
          {menu.menu_iconCls === 'HomeIcon' && <HomeIcon />}
          {menu.menu_iconCls === 'PeopleAltIcon' && <PeopleAltIcon />}
          {menu.menu_iconCls === 'AssignmentIndIcon' && <AssignmentIndIcon />}
          {menu.menu_iconCls === 'AddToQueueIcon' && <AddToQueueIcon />}
          {menu.menu_iconCls === 'BusinessIcon' && <BusinessIcon />}
          {menu.menu_iconCls === 'PollIcon' && <PollIcon />}
          {menu.menu_iconCls === 'DashboardIcon' && <DashboardIcon />}
          {menu.menu_iconCls === 'MedicalServicesIcon' && <MedicalServicesIcon />}
        </SvgIcon>
      ),
      ...(menu.hasOwnProperty("items") && menu.items.length > 0 ? { items: menu.items } : {})
    }));
  }, [sideMenus]);

  return [{ items: menuItems }];
};


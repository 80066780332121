export const paths = {
  index: '/',
  dashboard: {
    index: '/dashboard',  
    account: '/dashboard/account',  
    blank: '/dashboard/blank',
    organizations: {
      index: '/organizations',
      details: '/organizations/:customerId',
      edit: '/organizations/:customerId/edit',
      add: '/organizations/add',
      division: {
        index: '/organizations/:customerId/:tenantId/:customerName/divisions',
        add: '/organizations/:customerId/:tenantId/:customerName/divisions/add',
        edit: '/organizations/:customerId/:tenantId/:customerName/divisions/:divisionId/edit',
        subdivision: {
          add:'/organizations:customerId/customerName/divisions/:divisionId/:divisionName/subdivisions/add',
          edit: '/organizations:customerId/customerName/divisions/:divisionId/:divisionName/subdivisions/:subdivisionId/edit'
        }
      },
      
      customerUsers: {
        add: '/customerUsers/add',
        details: '/customerUsers/'
      }
    },
    surveys: {
      index: '/surveys/:surveyId',
      edit: '/surveys/:surveyId/:questionId/edit',
      details: '/surveys/:surveyId/:questionId/details',
    },
    users: {
      index: '/users',
      details: '/users/:userId',
      edit: '/users/:userId/edit',
      add: '/users/add',
      report: '/users/report'
    },
   rolemanagement: {
      index: '/roles',
      add: '/roles/add',
      details:'/roles/:roleId',
      edit:'/roles/:roleId/edit'  
    },
    programmes :{
      index:'/classes',
      add: '/classes/add'
    },
    ecommerce: '/dashboard/ecommerce',
    fileManager: '/dashboard/file-manager',
    invoices: {
      index: '/dashboard/invoices',
      details: '/dashboard/invoices/:orderId'
    }
  }, 
  notAuthorized: '/401',
  notFound: '/404',
  serverError: '/500'
};
